import * as Sentry from '@sentry/nuxt';
import packageJson from './package.json';

const {public: {sentry}} = useRuntimeConfig();

if (sentry.dsn) {
  Sentry.init({
    // @ts-ignore
    app         : useNuxtApp().vueApp,
    dsn         : sentry.dsn,
    environment : sentry.environment,
    integrations: [Sentry.captureConsoleIntegration()],
    release     : `${packageJson.name}@${packageJson.version}`,
  });
}

